import React from 'react'
import { SectionPartners } from '../../../types/sections'
import { Box, Heading } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../../utils/constants'
import { PartnerCard } from '../../partners'

function Partners(props: SectionPartners): JSX.Element {
  const { id, sectionId, headline, partners, partnerLabel, buttonLabel } = props

  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row center={'xs'}>
          <Col xs={12} md={10} lg={8}>
            <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
              {headline}
            </Heading>
          </Col>
        </Row>

        <Box mt={[5, 6, 7]}>
          <Row style={{ rowGap: '32px' }}>
            {partners.map((partner) => {
              return (
                <Col key={partner.id} xs={12} sm={6} md={4}>
                  <PartnerCard partner={partner} buttonLabel={buttonLabel} tagLabel={partnerLabel} />
                </Col>
              )
            })}
          </Row>
        </Box>
      </Grid>
    </Box>
  )
}

export default Partners
