import React from 'react'
import { Box, Flex, Heading, Tag, Text, Button, Image } from '../../../atoms'
import { PartnerProps } from '../../../types/blocks'

function PartnerCard({
  partner,
  buttonLabel,
  tagLabel,
}: {
  partner: PartnerProps
  buttonLabel: string
  tagLabel: string
}) {
  return (
    <Box border={'1px solid black'} boxShadow={'card'} borderRadius={8} width={'100%'} height={'100%'}>
      <Flex flexDirection={'column'} p={[5, 5, 6]} height={'100%'}>
        <Box flex={'1'}>
          <Flex justifyContent={'space-between'} width={'100%'} mb={5} gap={16}>
            {partner.logo && (
              <Box>
                <Image alt={partner.logo.alt ?? partner.name ?? ''} image={partner.logo.gatsbyImageData} />
              </Box>
            )}
            <Tag light color='purple' label={tagLabel} />
          </Flex>
          <Heading as={'h3'} type='heading3'>
            {partner.name}
          </Heading>
          {partner.description && (
            <Box mt={2}>
              <Text type={'small'}>{partner.description}</Text>
            </Box>
          )}
        </Box>

        {partner.websiteUrl && (
          <Flex mt={4} flex={'0 0 auto'} width={'100%'}>
            <a href={partner.websiteUrl} rel={'noreferrer noopener'} target={'_blank'}>
              <Button size={'sm'} buttonType={'secondary'} label={buttonLabel} />
            </a>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default PartnerCard
